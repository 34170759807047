<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Navigation
                </v-section-heading>

                <v-section-links :menu="menu" />
            </v-section>
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-model-form :id="$route.params.role" module="roleModule">
                <template v-slot:default="{ model, refreshModel }">
                    <v-section>
                        <v-section-heading>
                            <template v-slot:default>
                                {{ model.name }} - {{ $route.meta.title }}
                            </template>

                            <template v-slot:description>
                                Users: {{ model.users_count }}
                            </template>

                            <template v-slot:actions>
                                <div class="block lg:hidden">
                                    <v-button color="primary" @click.stop="toggleSidebar">
                                        <i class="fa fa-list-ul" />
                                    </v-button>
                                </div>
                            </template>
                        </v-section-heading>

                        <v-ghost :role="model" @role-updated="refreshModel" />
                    </v-section>
                </template>
            </v-model-form>
        </template>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            menu: [
                {
                    label: "Summary",
                    route: { name: "roles.show.summary" },
                    description: "Manage the role's basic information, and permissions.",
                    icon: "fa fa-id-card",
                },
                {
                    label: "Users",
                    route: { name: "roles.show.users" },
                    description: "View all the users who are assigned to the role.",
                    icon: "fa fa-users",
                },
            ],
            query: {
                withLoad: [
                    "permissions",
                ],
            },
        };
    },
};
</script>
